<template>
  <v-container>
    <v-app-bar dark app color="primary" clipped-right>
      <v-app-bar-nav-icon>
        <v-icon>mdi-poll</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>Klassenübersicht</v-toolbar-title>
      <v-spacer />
      <SearchField
        v-model="search"
        hint="Klasse (z.B. 22, G23, W25a), Klassenlehrer*in, SF (z.B BG, S, PAM)"
      ></SearchField>
    </v-app-bar>

    <v-toolbar color="" flat class="mb-4">
      <span>{{ filteredItems.length }} von {{ items.length }} Klassen</span>
      <v-spacer></v-spacer>
      <v-dialog max-width="800" width="100%" v-model="statisticsDialog">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="success"
            fab
            depressed
            small
            class="ml-2"
          >
            <v-icon>mdi-poll</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="pa-0">
            <v-toolbar color="success" light class="mb-2">
              Übersicht
              <v-spacer />
              <v-btn @click="statisticsDialog = false" icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
          </v-card-title>
          <v-card-text class="pa-0">
            <v-data-table
              :headers="statisticsHeaders"
              hide-default-footer
              :items="statisticsItems"
              :items-per-page="-1"
            >
            </v-data-table>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="statisticsDialog = false"
              ><v-icon left>mdi-close</v-icon> Schliessen</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog max-width="800" width="100%" v-model="mailDialog" scrollable>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="warning"
            fab
            depressed
            small
            class="ml-2"
          >
            <v-icon>mdi-email</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="pa-0">
            <v-toolbar color="warning" light class="mb-2">
              E-Mails der gewählten Klassen
              <v-spacer />
              <EmailClient />
              <v-btn @click="mailDialog = false" icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
          </v-card-title>
          <v-card-text class="pa-0">
            <v-tabs>
              <v-tab v-for="dl in dls" v-bind:key="'tab' + dl.description">
                {{
                  dl.description.replace("E-Mail an", "").replace(" der", "")
                }}
              </v-tab>

              <v-tab-item v-for="dl in dls" v-bind:key="dl.description">
                <v-card-title>
                  <Mailto :dl="dl.emails" :bcc="dl.bcc"
                    >{{ dl.description }} ({{ dl.emails.length }})</Mailto
                  >
                </v-card-title>
                <v-card-text>
                  {{ dl.emails.join(", ") }}
                </v-card-text>
              </v-tab-item>
            </v-tabs>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="mailDialog = false"
              ><v-icon left>mdi-close</v-icon> Schliessen</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog max-width="500" width="100%" v-model="reportDialog" scrollable>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="info"
            depressed
            fab
            small
            class="ml-2"
          >
            <v-icon>mdi-text-box-multiple</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="pa-0">
            <v-toolbar color="info" dark class="mb-2">
              Berichte für gewählte Klassen
              <v-spacer />
              <v-btn @click="reportDialog = false" icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
          </v-card-title>
          <v-card-text>
            <v-list dense>
              <ReportItem
                v-for="(report, index) in reports"
                v-bind:key="index"
                :report="report"
                :ids="schoolClassIds"
              >
              </ReportItem>
            </v-list>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="reportDialog = false"
              ><v-icon left>mdi-close</v-icon> Schliessen</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-card class="mt-2">
      <v-data-table
        :loading="loading"
        :headers="headers"
        hide-default-footer
        :items="filteredItems"
        :items-per-page="-1"
        sort-by="code"
      >
        <template v-slot:item.teacher="{ item }">
          <PersonItem :value="item.teacher" />
        </template>
        <template v-slot:item.bilingual="{ item }">
          {{ item.bilingual.join(", ") }}
        </template>
        <template v-slot:item.majorSubjects="{ item }">
          {{ item.majorSubjects.join(", ") }}
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import { comparePeople } from "common/utils/people.js";

import EmailClient from "@/components/settings/EmailClient.vue";
import Mailto from "@/components/Mailto.vue";
import PersonItem from "@/components/PersonItem.vue";
import ReportItem from "@/components/ReportItem.vue";
import SearchField from "@/components/SearchField.vue";

export default {
  name: "SchoolClassesOverview",
  components: {
    EmailClient,
    Mailto,
    PersonItem,
    ReportItem,
    SearchField,
  },
  data() {
    return {
      mailDialog: false,
      reportDialog: false,
      statisticsDialog: false,
      fab: false,
      dls: [],
      reports: [],
      statisticsHeaders: [],
      statisticsItems: [],
      headers: [
        { text: "Klasse", value: "code" },
        {
          text: "Klassenlehrperson",
          value: "teacher",
          sort: comparePeople,
        },
        { text: "Stv. KL", value: "substituteTeacher.code" },
        { text: "BI", value: "bilingual" },
        { text: "SF", value: "majorSubjects" },
        { text: "m", value: "studentCount.male" },
        { text: "w", value: "studentCount.female" },
        { text: "d", value: "studentCount.fluid" },
        { text: "Total", value: "studentCount.total" },
      ],
      loading: false,
      search: "",
      items: [],
    };
  },
  computed: {
    schoolClassIds() {
      return this.filteredItems.map((item) => item.id);
    },
    addressTypesVisible() {
      return this.selectedReport === 3 || this.selectedReport === 4;
    },
    filteredItems() {
      if (!this.search) {
        return this.items;
      }
      return this.items.filter(
        (item) =>
          item.code.toLowerCase().includes(this.search.toLowerCase()) ||
          (item.teacher && item.teacher.code === this.search.toLowerCase()) ||
          (item.substituteTeacher &&
            item.substituteTeacher.code === this.search.toLowerCase()) ||
          (item.majorSubjects &&
            item.majorSubjects.includes(this.search.toUpperCase()))
      );
    },
  },
  watch: {
    search(oldValue, newValue) {
      if (oldValue != newValue) {
        if (this.search) {
          localStorage.setItem("schoolClassOverviewSearch", this.search);
        } else {
          localStorage.removeItem("schoolClassOverviewSearch");
        }
      }
    },
    async mailDialog(value) {
      if (value) {
        const origDls = await this.apiList({
          resource: "schoolclass/mail",
          query: "id=" + this.schoolClassIds.join("&id="),
        });
        const dls = [];
        origDls.forEach((dl) => {
          if (dl.emails.length <= 400) {
            dls.push(dl);
          } else {
            let start = 0;
            let end = 0;
            while (start < dl.emails.length) {
              end = Math.min(dl.emails.length, start + 400);
              dls.push({
                description: dl.description + " " + (start + 1) + " - " + end,
                bcc: dl.bcc,
                emails: dl.emails.slice(start, end),
              });
              start = end;
            }
          }
        });
        this.dls = dls;
      }
    },
  },
  methods: {
    async calculateStatistics() {
      const divisions = await this.apiList({ resource: "common/division" });
      const grades = await this.apiList({ resource: "common/grade" });
      const map = {};
      divisions.forEach((division) => {
        const subMap = {};
        grades.forEach((grade) => {
          subMap[grade.id] = 0;
        });
        map[division.id] = subMap;
      });
      this.items.forEach((item) => {
        if (item.grade) {
          map[item.division.id][item.grade.id] += item.studentCount.total;
        }
      });
      const headers = [{ text: "Abteilung", value: "division" }];
      const totalLine = { division: "Total" };
      grades.forEach((grade) => {
        headers.push({ text: grade.code, value: grade.code });
        totalLine[grade.code] = 0;
      });
      totalLine.total = 0;
      headers.push({ text: "Total", value: "total" });
      const items = [];
      divisions.forEach((division) => {
        const line = { division: division.code };
        let sum = 0;
        grades.forEach((grade) => {
          const count = map[division.id][grade.id];
          totalLine[grade.code] += count;
          line[grade.code] = count;
          sum += count;
        });
        line.total = sum;
        totalLine.total += line.total;
        items.push(line);
      });

      items.push(totalLine);
      this.statisticsItems = items;
      this.statisticsHeaders = headers;
    },
  },
  async created() {
    this.loading = true;
    this.items = await this.apiList({ resource: "schoolclass/schoolclass" });
    this.calculateStatistics();
    this.reports = await this.apiList({
      resource: "app/report",
      query: "type=schoolClasses",
    });
    if (
      localStorage.getItem("schoolClassOverviewSearch") &&
      localStorage.getItem("schoolClassOverviewSearch") != "null"
    ) {
      this.search = localStorage.getItem("schoolClassOverviewSearch");
    }
    this.loading = false;
  },
};
</script>
